import React, { useRef } from "react";
import AliceCarousel from "react-alice-carousel";
import BlogCarouselSlide from "./BlogCarouselSlide";
import "./BlogCarousel.scss";
import img1 from "../../assets/images/Blog/CanadaDay/CanadaDay_1.jpg";
import img2 from "../../assets/images/Blog/young_adult.jpg";
import img3 from "../../assets/images/Blog/cabin_fever.jpg"; // Ensure this image exists
import img4 from "../../assets/images/Blog/lol.png";
const items = [
  {
    image: img4, // the image for the grant application letter
    title: "Grant Application Recommendation Letter",
    date: "October 8, 2024", // the date of the letter
    description:
      "Recommendation letter from Councillor John Kovac for Sinai Foundation Canada",
    link: "https://sinaifoundation.ca/blog/grant-application-recommendation-letter",
  },
  {
    image: img3,
    title: "Cabin Fever",
    date: "Blog Date 3",
    description: "Tips to overcome Cabin Fever",
    link: "https://sinaifoundation.ca/blog/cabin-fever",
  },
  {
    image: img2,
    title: "Covid-19 and Mental Health",
    date: "Blog Date 2",
    description: "Tips regarding COVID-19 and Mental Health",
    link: "https://sinaifoundation.ca/blog/covid-19-young-adults",
  },

  {
    image: img1,
    title: "Canada Day",
    date: "Blog Date 1",
    description: "The Celebration of Our Wonderful Nation!",
    link: "https://sinaifoundation.ca/blog/canada-day",
  },
];

export default function BlogCarousel() {
  const ref = useRef();

  const handleDragStart = (e) => e.preventDefault();

  const carouselItems = items.map((item, index) => (
    <div className="item" key={index}>
      <BlogCarouselSlide
        image={item.image}
        title={item.title}
        date={item.date}
        description={item.description}
        link={item.link}
        onDragStart={handleDragStart}
      />
    </div>
  ));

  return (
    <div className="blogCarousel">
      <AliceCarousel
        ref={ref}
        items={carouselItems}
        mouseTracking
        disableButtonsControls
        disableDotsControls
        responsive={{
          0: { items: 1 }, // Ensures only one item is shown at a time
        }}
        infinite
      />
      <button
        className="blogCarousel__btn blogCarousel__btn--left"
        onClick={() => ref.current.slidePrev()}
      >
        <i className="blogCarousel__icon fas fa-chevron-circle-left" />
      </button>
      <button
        className="blogCarousel__btn blogCarousel__btn--right"
        onClick={() => ref.current.slideNext()}
      >
        <i className="blogCarousel__icon fas fa-chevron-circle-right" />
      </button>
    </div>
  );
}
